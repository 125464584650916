import React from "react";
import Image from "next/image";

export default function EmptyState({ title, description }) {
    return (
        <div className="empty-state-holder">
            <div className="empty-state-img">
                <Image
                    loading="lazy"
                    src={`/assets/common/images/EmptySvg.svg`}
                    // sizes="(min-width: 75em) 33vw, (min-width: 48em) 50vw, 100vw"
                    objectFit="contain"
                    width={334}
                    height={316}
                    alt="image"
                />
            </div>
            <div className="empty-state-content">
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
}
