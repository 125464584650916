import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../config/network/useApi";
import { useRouter } from "next/router";
import { queryKeys } from "@config/react-query/queryKeys";
import { getLangCodeByLocal } from "@components/shared/utils/getLangCodeByLocal";
import { CMS } from "@components/admin/dashboards/components/pages/AllPages/enum";
import { message } from "antd";
import { useTranslation } from "next-i18next";

export const useGetPackageTypes = ({ lang, isEnabled = false }: { lang: string | undefined | any; isEnabled: boolean }) => {
	const api = useApi("PackagesAPI");
	const http = async () => {
		const res = await api.get(`/PackageType/GetAll`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getPackageTypes], http, {
		select: (res) => {
			return res?.responseData?.items;
		},
		enabled: isEnabled
		// cacheTime: 1000 * 60 * 60 * 24
		// staleTime: 1000 * 60 * 60 * 24,
	});
	return { data, isFetching, isLoading };
};

export const useGetPackageTypeById = ({ id }: { id: string }) => {
	const api = useApi("PackagesAPI");
	const http = async () => {
		const res = await api.get(`/PackageTypeById`, id);
		return res;
	};
};

export const useGetActivityTypes = ({ lang, isEnabled = false }: { lang: string | undefined | any; isEnabled: boolean }) => {
	const api = useApi("PackagesAPI");
	const http = async () => {
		const res = await api.get(`/ThingsToDoType/GetAll`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getThingsToDoType], http, {
		select: (res) => {
			return res?.responseData?.items;
		},
		enabled: isEnabled

		// enabled: !!lang
		// cacheTime: 1000 * 60 * 60 * 24
		// staleTime: 1000 * 60 * 60 * 24,
	});
	return { data, isFetching, isLoading };
};

export const useGetPackageItemTypes = ({ lang, isEnabled = false, onSuccess }: { lang: string | undefined | any; isEnabled: boolean; onSuccess?: (res: any) => void }) => {
	const api = useApi("PackagesAPI");
	const http = async () => {
		const res = await api.get(`/PackageItemType/GetAll`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getPackageItemType], http, {
		select: (res) => {
			return res?.responseData?.items;
		},
		onSuccess: (res) => {
			onSuccess && onSuccess(res);
		},
		enabled: isEnabled
		// cacheTime: 1000 * 60 * 60 * 24
		// staleTime: 1000 * 60 * 60 * 24,
	});
	return { data, isFetching, isLoading };
};

export const useGetActivityItemTypes = ({ lang, isEnabled = false }: { lang: string | undefined | any; isEnabled: boolean }) => {
	const api = useApi("PackagesAPI");
	const http = async () => {
		const res = await api.get(`/ThingsToDoItemType/GetAll`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getThingsToDoItemType], http, {
		select: (res) => {
			return res?.responseData?.items;
		},
		enabled: isEnabled

		// enabled: !!lang
		// cacheTime: 1000 * 60 * 60 * 24
		// staleTime: 1000 * 60 * 60 * 24,
	});
	return { data, isFetching, isLoading };
};

export const useGetDestinations = ({ lang }: { lang: string | undefined | any }) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/Destination/GetAllDestinations`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getDestinations, lang], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24
	});
	return { data, isFetching, isLoading };
};

export const useGetDestinationsPaginated = () => {
	const api = useApi("MasterAPI");
	return useInfiniteQuery(
		[queryKeys.masterdata.getDestinationsPaginated],
		async ({ pageParam = 0 }) => {
			return await api.get(`/Destination/SearchByName?PageSize=30&PageIndex=${pageParam}`);
		},
		{
			// select: (res: any) => {
			//   console.log(res?.pages[0],'ers')
			// 	return res?.pages[0]?.responseData;
			// },
			getNextPageParam: (lastPage, pages) => {
				// const
				return lastPage?.responseData < 30 ? undefined : Math.round(pages?.reduce((prev, res) => res?.responseData?.length + prev, 0) / 30);
			},
			staleTime: 1000 * 60 * 60 * 24,
			cacheTime: 1000 * 60 * 60 * 24
		}
	);
};

export const useGetSearchDestinations = ({ name }: { name: string }) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/Destination/SearchByName?SearchKey=${name}`);
		return res;
	};

	return useQuery([queryKeys.masterdata.getSearchDestinations, name], http, {
		enabled: !!name,
		select: (res) => {
			return res?.responseData;
		}
	});
};

export const useGetCurrentCountry = ({ countryId, name, enabled, onSuccess }: { countryId: string | string[] | undefined; name: string; enabled: boolean; onSuccess?: (res: any) => void }) => {
	const api = useApi("MasterAPI");
	const router = useRouter();
	const http = async () => {
		const res = await api.get(`/Destination/SearchByName?ID=${countryId}&SearchKey=${name}`);
		return res;
	};

	return useQuery([queryKeys.masterdata.getCurrentCountry, { countryId, lang: router.locale }], http, {
		select: (res) => {
			return res?.responseData[0];
		},
		enabled: !!countryId && enabled,
		onSuccess: (res) => onSuccess && onSuccess(res)
	});
};

export const useGetCountries = ({ lang, enabled = true }: { lang: string | undefined | any; enabled?: boolean }) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/Country/GetAllCountries?countryProviderType=6`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getCountries, lang], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24,
		enabled: enabled
	});
	return { data, isFetching, isLoading };
};

export const useGetAllNationlities = ({ lang }: { lang: string | undefined | any }) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/Country/GetAllNationlities`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getNationlities, lang], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24
	});
	return { data, isFetching, isLoading };
};

export const useGetAllNationlitiesPagination = ({ lang, index = 0, key = null, enabled = true }: any) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const isKey = key ? "&SearchKey=" + key : "";
		const res = await api.get(`/Country/GetAllNationlities?PageSize=15&PageIndex=${index}` + isKey);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getNationlities, lang, index, key], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24,
		enabled
	});
	return { data, isFetching, isLoading };
};

export const useGetAllCountriesPagination = ({ lang, index = 0, key = null, enabled = true }: any) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const isKey = key ? "&SearchKey=" + key : "";
		const res = await api.get(`/Country/GetAllCountries?PageSize=15&PageIndex=${index}` + isKey);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getCountries, lang, index, key], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24,
		enabled
	});
	return { data, isFetching, isLoading };
};

export const useGetTanentCurrencies = ({ lang, enabled = true }: { lang: string | undefined | any; enabled?: boolean }) => {
	const api = useApi("PaymentAPI", {
		headers: {
			"Accept-Language": getLangCodeByLocal(lang)
		}
	});

	const http = async () => {
		return await api.get(`/Currency/GetCurrenciesList`);
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getAllTanentCurrencies, { lang }], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24,
		enabled
	});
	return { data, isFetching, isLoading };
};

export const useGetCurrencies = ({ lang, enabled = true }: { lang: string | undefined | any; enabled?: boolean }) => {
	const api = useApi("PaymentAPI", {
		headers: {
			"Accept-Language": getLangCodeByLocal(lang)
		}
	});

	const http = async () => {
		return await api.get(`/Currency/GetAllCurrencies`);
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getAllCurrencies, { lang }], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24,
		enabled
	});
	return { data, isFetching, isLoading };
};

export const useGetGeoLocation = ({ enabled }: { enabled?: boolean }) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/Geolocation/GetUserGeolocationInfo`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getGeoLocation], http, {
		select: (res) => {
			return res?.responseData;
		},
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24,
		enabled
	});
	return { data, isFetching, isLoading };
};

export const useGetCites = ({ id, lang }: { id: string | undefined | any; lang: string | undefined | any }) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/City/GetCityByCountryID?CountryID=${id}`);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getCities, id, lang], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: !!id,
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24
	});
	return { data, isFetching, isLoading };
};

export const useGetRoutes = ({ lang, isEnable }: { lang: string | undefined | any; isEnable: boolean }) => {
	const api = useApi("UmrahAPI");
	const https = async () => {
		const res = await api.post(`/UmraTransportation/GetAllRoutes`, {});
		return res;
	};
	return useQuery([queryKeys.masterdata.getRoutes, lang], https, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: isEnable,
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24
	});
};

export const useGetVehicleTypes = ({ lang, isEnable }: { lang: string | undefined | any; isEnable: boolean }) => {
	const api = useApi("UmrahAPI");
	const https = async () => {
		const res = await api.post(`/UmraTransportation/GetAllVehicleTypes`, {});
		return res;
	};
	return useQuery([queryKeys.masterdata.getVehicleTypes, lang], https, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: isEnable,
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24
	});
};

export const useGetCountriesByRegionId = (regionId: any, enabled: any) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		return await api.get(`/Country/GetCountryByRegionID?RegionID=` + regionId);
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.masterdata.getCountriesByRegionId + "?RegionID=" + regionId, enabled], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled,
		staleTime: 1000 * 60 * 60 * 24,
		cacheTime: 1000 * 60 * 60 * 24
	});
	return { data, isFetching, isLoading };
};

export const useGetCMS = (type: CMS) => {
	const api = useApi("MasterAPI");
	const http = async () => {
		const res = await api.get(`/CMSData/GetCMS?CMSDataType=` + type);
		return res;
	};
	const { data, isFetching, isLoading } = useQuery([queryKeys.admin.CMS], http, {
		select: (res) => {
			return res?.responseData;
		}
	});
	return { data, isFetching, isLoading };
};

export const useUpdateCMS = (type: CMS) => {
	const queryClient = useQueryClient();
	const api = useApi("MasterAPI");
	const router = useRouter();
	return useMutation(
		(data: any) => {
			return api.put("/CMSData/EditCMS", data);
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries([queryKeys.admin.CMS, { type }]);
				message.success(data?.responseMessage);
				// Return back to list page after added
				router.back();
			}
		}
	);
};
export const useGetHomepage = () => {
	const api = useApi("AuthenticationAPI");
	const http = async () => await api.get(`/Tenant/GetHomePage`);
	const { data, isFetching, isLoading } = useQuery([queryKeys.admin.HomePages], http, {
		select: (res) => {
			return res?.responseData;
		}
	});
	return { data, isFetching, isLoading };
};

export const useUpdateHomepage = () => {
	const queryClient = useQueryClient();
	const api = useApi("AuthenticationAPI");
	const router = useRouter();
	return useMutation(
		(data: any) => {
			return api.put("/Tenant/EditHomeImages", data);
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries([queryKeys.admin.HomePages]);
				message.success(data?.responseMessage);
				// Return back to list page after added
				// router.back();
			}
		}
	);
};
export const useGetAllDestination = () => {
	const api = useApi("MasterAPI");
	const http = async () => await api.get(`/HomeDestination/GetAllDestination`);
	const { data, isFetching, isLoading } = useQuery([queryKeys.admin.getDestinations], http, {
		select: (res) => {
			return res?.responseData;
		}
	});
	return { data, isFetching, isLoading };
};

export const useUpdateDestination = () => {
	const queryClient = useQueryClient();
	const api = useApi("MasterAPI");
	const router = useRouter();
	return useMutation(
		(data: any) => {
			return api.put("/HomeDestination/EditDestination", data);
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries([queryKeys.admin.getDestinations]);
				message.success(data?.responseMessage);
				// Return back to list page after added
				// router.back();
			}
		}
	);
};

export const useGetContact = () => {
	const api = useApi("AuthenticationAPI");
	const http = async () => await api.get(`/Tenant/Getcontact`);
	const { data, isFetching, isLoading } = useQuery([queryKeys.admin.Contact], http, {
		select: (res) => {
			return res?.responseData;
		}
	});
	return { data, isFetching, isLoading };
};

export const useUpdateContact = () => {
	const { t } = useTranslation("admin");
	const queryClient = useQueryClient();
	const api = useApi("AuthenticationAPI");
	return useMutation(
		(data: any) => {
			return api.put("/Tenant/Editcontact", data);
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries([queryKeys.admin.Contact]);
				message.success(t("message.updated_successfully"));
			}
		}
	);
};
