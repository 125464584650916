import { RootState } from "store/rootReducer";
import { useSelector } from "react-redux";
import axios, { AxiosInstance, AxiosStatic } from "axios";
import { GetawayAPI, MasterAPI, AuthenticationAPI, PackagesAPI, UmrahAPI, FilesAPI, PaymentAPI } from "@common/constants";
import { getSession } from "next-auth/react";
import Router from "next/router";

//  ENDPOINT

//  START FUNCTION
type baseUrlType = "MasterAPI" | "AuthenticationAPI" | "PackagesAPI" | "GetawayAPI" | "UmrahAPI" | "FilesAPI" | "PaymentAPI";
function useApi(baseUrl: baseUrlType, config = {}) {
	// const lang = useSelector((state: RootState) => state?.user?.lang);
	const getBaseUrlfromProps = () => {
		switch (baseUrl) {
			case "MasterAPI":
				return MasterAPI;
			case "AuthenticationAPI":
				return AuthenticationAPI;
			case "PackagesAPI":
				return PackagesAPI;
			case "GetawayAPI":
				return GetawayAPI;
			case "UmrahAPI":
				return UmrahAPI;
			case "FilesAPI":
				return FilesAPI;
			case "PaymentAPI":
				return PaymentAPI;
			default:
				return PackagesAPI;
		}
	};

	const generateLanguage = () => {
		// const Language = Router?.router?.state?.locale
		const Language = Router?.router?.locale;
		if (Language === "ar") return "ar-EG";
		if (Language === "tr") return "tr-TR";
		return "en-US";
	};

	const generateTimeffset = () => {
		const date = new Date();
		const timeOffset = date.getTimezoneOffset();
		return -(timeOffset / 60);
	};
	const lang = typeof window !== "undefined" ? (localStorage?.getItem("UserLanguage") && localStorage.getItem("UserLanguage")) || "en" : "en";
	const axiosInstance = () => {
		const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
		const userLocation = localStorage?.getItem("userLocation") && localStorage?.getItem("userLocation") !== "undefined" ? JSON?.parse(localStorage?.getItem("userLocation") || "{}") : null;
		const tenantID = localStorage?.getItem("tenantID") && localStorage?.getItem("tenantID") !== "undefined" ? JSON?.parse(localStorage?.getItem("tenantID") || "{}") : null;
		const TenantName = localStorage?.getItem("TenantName") && localStorage?.getItem("TenantName") !== "undefined" ? JSON?.parse(localStorage?.getItem("TenantName") || "{}") : null;

		const instanace = axios.create({
			baseURL: getBaseUrlfromProps(),
			timeout: 60000,
			headers: {
				// Authorization: `Bearer ${session?.accessToken}`,
				// "Accept-Language": generateLanguage(),
				"Accept-Language": lang === "ar" ? "ar-EG" : lang === "tr" ? "tr-TR" : "en-US",
				"Time-Offset": generateTimeffset(),
				"tenant-id": tenantID || "",
				TenantName: TenantName || "",
				"currency-code": localCurrency ? JSON.parse(localStorage?.getItem("UserCurrency") || "")?.code : "USD",
				countryId: userLocation?.countryId,
				regionId: userLocation?.regionId,
				"Content-Type": "application/json;",
				xsrfCookieName: "csrftoken",
				xsrfHeaderName: "X-CSRFToken",
				"Access-Control-Allow-Origin": "*"
			},
			...config
		});

		instanace.interceptors.request.use(async (request) => {
			const session = await getSession();
			if (session && request.headers) {
				// request.headers.A = `Bearer ${session.accessToken}`
				request.headers.Authorization = `Bearer ${session.accessToken}`;
			}
			return request;
		});

		return instanace;
	};

	// ############################
	async function get(route: string) {
		const { data } = await axiosInstance().get(route);
		return data;
	}

	async function post(route: string, body: any) {
		const { data } = await axiosInstance().post(route, body);
		return data;
	}

	async function put(route: string, body: any) {
		const { data } = await axiosInstance().put(route, body);
		return data;
	}

	async function patch(route: string, body: any) {
		const { data } = await axiosInstance().patch(route, body);
		return data;
	}

	async function del(route: string, body: any) {
		const { data } = await axiosInstance().delete(route, body);
		return data;
	}

	return { get, post, put, patch, delete: del };
}

export default useApi;
