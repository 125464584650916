export const queryKeys = {
	admin: {
		base: {
			getAdminList: "getAdminList"
		},
		CMS: "CMS",
		Contact: "Contact",
		getDestinations: "getDestinations",
		HomePages: "HomePages"
	},
	masterdata: {
		getPackageTypes: "getPackageTypes",
		getPackageItemType: "getPackageItemType",
		getThingsToDoItemType: "getThingsToDoItemType",
		getThingsToDoType: "getThingsToDoType",
		getUserLocation: "getUserLocation",
		getCountries: "getCountries",
		getNationlities: "getNationlities",
		getCities: "getCities",
		getAllCurrencies: "getAllCurrencies",
		getAllTanentCurrencies: "getAllTanentCurrencies",
		getGeoLocation: "getGeoLocation",
		getDestinations: "getDestinations",
		getDestinationsPaginated: "getDestinationsPaginated",
		getSearchDestinations: "getSearchDestinations",
		getCurrentCountry: "getCurrentCountry",
		getRoutes: "getRoutes",
		getVehicleTypes: "getVehicleTypes",
		getCountriesByRegionId: "getCountriesByRegionId",
		getDestinationsPaginated: "getDestinationsPaginated",
		getSearchDestinations: "getSearchDestinations",
		getCurrentCountry: "getCurrentCountry"
	},
	suppliers: {
		getSupplierList: "getSupplierList"
	},
	roles: {
		getById: "getById",
		getRoleList: "getRoleList"
	},
	members: {
		getMemberList: "getMemberList",
		getRecycleBinMemberList: "getRecycleBinMemberList"
	},
	package: {
		getSinglePackageB2c: "getSinglePackageB2c",
		getCompanyPackages: "getCompanyPackages",
		getPackageB2cList: "getPackageB2cList",
		useGetSlugPackages: "useGetSlugPackages",
		useGetSlugActivity: "useGetSlugActivity",
		getBookingConfirmationPackage: "getBookingConfirmationPackage",
		getBookingConfirmationUmurah: "getBookingConfirmationUmurah",
		getBookingPackage: "getBookingPackage",
		getBookingPackageList: "getBookingPackageList",
		getUmurahList: "getUmurahList",
		getPackageBookingsThingsToDoList: "getPackageBookingsThingsToDoList",
		getPackageBookingSummary: "getPackageBookingSummary",
		getPackageItemTypeById: "getPackageItemTypeById",
		regions: {
			getRegions: "getRegions"
		}
	},
	thingsToDo: {
		getSingleThingsToDoB2c: "getSingleThingToDoB2c",
		getCompanyThingsToDos: "getCompanyThingToDos",
		getThingsToDoB2cList: "getThingToDoB2cList",
		useGetSlugThingsToDos: "useGetSlugThingToDos",
		getBookingConfirmationThingsToDo: "getBookingConfirmationThingToDo",
		getBookingThingsToDo: "getBookingThingToDo",
		getBookingThingsToDoList: "getBookingThingToDoList",
		getThingsToDoBookingSummary: "getThingToDoBookingSummary",
		getThingsToDoItemTypeById: "getThingsToDoItemTypeById",
		regions: {
			getRegions: "getRegions"
		}
	},
	favourite: {
		getPackageFavouriteList: "getPackageFavouriteList",
		getThingsToDoFavouriteList: "getThingsToDoFavouriteList"
	},
	company: {
		getCompanyInfo: "getCompanyInfo"
	},
	visitKsa: {
		getDestinationsList: "getDestinationsList",
		getKsaPackagesList: "getKsaPackagesList",
		getKsaActivitysList: "getKsaActivitysList"
	},
	dashboard: {
		getDashboardSummary: "getDashboardSummary",
		getDashboardStatistics: "getDashboardStatistics"
	},
	wallet: {
		getWalletSummary: "getWalletSummary",
		getWalletTable: "getWalletTable",
		getWalletChart: "getWalletChart"
	},
	umrah: {
		// Transportation
		getTransportationList: "getTransportationList",
		getTransportationDetails: "getTransportationDetails",
		getSingleTransportation: "getSingleTransportation",
		getBookingDetails: "getBookingDetails",

		// Makah
		getMakkahDetails: "getMakkahDetails",
		getUmrahHotelList: "getUmrahHotelList",
		getUmrahHotelDropdown: "getUmrahHotelDropdown",
		getUmrahSingleRoom: "getUmrahSingleRoom",

		// Ground Service
		getGroundServiceList: "getGroundServiceList",
		getGroundServiceDetails: "getGroundServiceDetails",
		getSingleGroundService: "getSingleGroundService",

		// Other
		getAllQuestions: "getAllQuestions",
		getAllRelatives: "getAllRelatives",
		getAirports: "getAirports",
		bookingDetails: "bookingDetails"
	},
	groundService: {
		getGroundServiceList: "getGroundServiceList",
		getGroundServiceDetails: "getGroundServiceDetails"
	},
	shared: {
		buttonTranslation: "buttonTranslation"
	},
	partners: {
		getPartnersList: "getPartnersList"
	},
	cart: {
		getCartDetails: "getCartDetails",
		verifyCartOrder: "verifyCartOrder"
	},
	orders: {
		getOrderList: "getOrderList",
		getCartOrderDetails: "getCartOrderDetails",
		getUpcomingOrderList: "getUpcomingOrderList",
		getPreviousOrderList: "getPreviousOrderList",
		cancelOrderDetails: "cancelOrderDetails"
	}
};
