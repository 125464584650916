import { AwesomeCar, AwesomeHome, AwesomePrayingHands, GettingStarted1, GettingStarted2, GettingStarted3, Group_15018, Group_15095, MaterialFlightTakeoff, NounBus2558229 } from "@common/icons";
// import UploadField from "@components/fields/UploadField";
import { companyNameValidator, emailValidator, notemptyValidator } from "./validations";
import {
	Group16568,
	NounBarbecue,
	NounLeisure,
	NounMakkah,
	NounTourism,
	Path12622,
	// CrownIcon,
	Grid5,
	Notifications,
	ProfileIcon,
	Refresh,
	Group15677,
	IconMaterialLocation,
	LinearTicket,
	Receiptminus,
	BriefCase
} from "@common/icons";
const symbolRegex: RegExp = new RegExp(/[*@!#%&$+_()^~{}]+/);
export const whiteSpaceRegex: RegExp = new RegExp(/^\S*$/);

import { useTranslation } from "next-i18next";
// const { t } = useTranslation("website");

export const sort_options = (t: any) => [
	{ name: t("profile.package.LowToHigh"), value: 1 },
	{ name: t("profile.package.HighToLow"), value: 2 }
	// { name: t("profile.package.NearestDate"), value: 3 },
	// { name: t("profile.package.FarestDate"), value: 4 }
];

export const packageTypesArray = [
	{
		id: 1,
		icon: NounMakkah,
		name: "Hajj"
	},
	{
		id: 2,
		icon: Group16568,
		name: "Umrah"
	},
	{
		id: 3,
		icon: NounLeisure,
		name: "Leisure"
	},
	{
		id: 4,
		icon: Path12622,
		name: "Safari"
	},
	{
		id: 5,
		icon: NounBarbecue,
		name: "weekend"
	},
	{
		id: 6,
		icon: NounTourism,
		name: "Educational Tourism"
	}
];

export const CancellationPolicyTypes = [
	{
		name: "cancellationPolicyType",
		label: "Refundable",
		type: "radio",
		value: 2,
		defaultChecked: true
	},
	{
		name: "cancellationPolicyType",
		label: "Non Refundable",
		type: "radio",
		value: 1,
		defaultChecked: false
	},
	{
		name: "cancellationPolicyType",
		label: "Custom Refund",
		type: "radio",
		value: 3,
		defaultChecked: false
	}
];

export const tripActivitiesList = [
	{ id: 0, icon: Group15677, name: "Package seats", sub: "13", width: "66px" },
	{ id: 2, icon: Group15677, name: "Transportation", sub: "AC private car or van (Optional)", width: "132px" },
	{ id: 3, icon: Group15677, name: "Equipment", sub: "-Private Tour Guide. -Camel ride Included.", width: "150px" },
	{ id: 4, icon: Group15677, name: "Flight ticket", sub: "-Flight ticket included", width: "126px" }
];

export const countryList = [
	{ country: "Saudi", productId: 1, categoryId: 1, Item1Type: "Saudi" },
	{ country: "Egypt", productId: 2, categoryId: 2, Item1Type: "Egypt" },
	{ country: "England", productId: 3, categoryId: 3, Item1Type: "England" },
	{ country: "India", productId: 4, categoryId: 4, Item1Type: "India" },
	{ country: "Sudan", productId: 5, categoryId: 5, Item1Type: "Sudan" }
];

export const codeList = [{ code: "+20" }, { code: "+966" }, { code: "+50" }, { code: "+30" }, { code: "+000" }];

export const checkBoxItemList = [
	{ id: "0", name: "Category name", defaultValue: true },
	{ id: "1", name: "Example", defaultValue: true },
	{ id: "2", name: "Category name", defaultValue: false },
	{ id: "3", name: "Category name", defaultValue: false },
	{ id: "4", name: "Example name", defaultValue: false },
	{ id: "5", name: "Category name", defaultValue: false },
	{ id: "6", name: "Category name", defaultValue: false },
	{ id: "7", name: "Example name", defaultValue: false },
	{ id: "8", name: "Example name", defaultValue: false }
];

export const starsList = [
	{ stars_rate: "1 Star", productId: 1, categoryId: 1 },
	{ stars_rate: "2 stars", productId: 2, categoryId: 2 },
	{ stars_rate: "3 stars", productId: 3, categoryId: 3 },
	{ stars_rate: "4 stars", productId: 4, categoryId: 4 },
	{ stars_rate: "5 stars", productId: 5, categoryId: 5 }
];

export const buyerTypes = [
	{ id: 2, label: "End Customer", value: 2 },
	{ id: 3, label: "Business Account", value: 3 }
];

export const fieldsRegisterBussinessArray = [
	{
		name: "companyName",
		label: "businessName",
		placeholder: "businessName",
		required: true,
		type: "text",
		validateAppear: true
	},
	{
		name: "userName",
		label: "email",
		placeholder: "email",
		translate: false,
		validator: [
			{
				required: true,
				message: `empty`
			},
			{
				min: 8,
				message: `len8`
			},
			{ type: "email", message: `validEmail` }
		],
		required: true,
		type: "email",
		validateAppear: true
	},
	{
		name: "password",
		label: "password",
		placeholder: "password",
		translate: false,
		required: true,
		type: "password",
		validator: [
			{
				required: true,
				message: `empty`
			},
			{
				min: 8,
				message: `len8`
			},
			{
				pattern: symbolRegex,
				message: "oneSymbol"
			},
			{
				pattern: whiteSpaceRegex,
				message: "len8whiteSpaces"
			}
		],
		validateAppear: false
	},
	{
		name: "confirm_password",
		label: "confirmPassword",
		placeholder: "confirmPassword",
		translate: false,
		required: true,
		type: "password",
		validator: [
			{
				required: true,
				message: `empty`,
				customValidator: "passSame"
			}
		],
		validateAppear: false
	}
];

export const fieldsRegisterArray = [
	{
		name: "userName",
		label: "email",
		placeholder: "email",
		translate: false,
		validator: [
			{
				required: true,
				message: `empty`
			},
			{
				min: 8,
				message: `len8`
			},
			{ type: "email", message: `validEmail` }
		],
		required: true,
		type: "email",
		validateAppear: true
	},
	{
		name: "password",
		label: "password",
		placeholder: "password",
		translate: false,
		required: true,
		type: "password",
		validator: [
			{
				required: true,
				message: `empty`
			},
			{
				min: 8,
				message: `len8`
			},
			{
				pattern: symbolRegex,
				message: "oneSymbol"
			},
			{
				pattern: whiteSpaceRegex,
				message: "len8whiteSpaces"
			}
		],
		validateAppear: false
	},
	{
		name: "confirm_password",
		label: "confirmPassword",
		placeholder: "confirmPassword",
		translate: false,
		required: true,
		type: "password",
		validator: [
			{
				required: true,
				message: `empty`,
				customValidator: "passSame"
			}
		],
		validateAppear: false
	}
];

export const fieldsResetPasswordArray = [
	{
		name: "password",
		label: "password",
		placeholder: "password",
		translate: false,
		required: true,
		type: "password",
		validator: [
			{
				required: true,
				message: `empty`
			},
			{
				min: 8,
				message: `len8`
			},
			{
				pattern: symbolRegex,
				message: "oneSymbol"
			},
			{
				pattern: whiteSpaceRegex,
				message: "len8whiteSpaces"
			}
		],
		validateAppear: false
	},
	{
		name: "confirm_password",
		label: "confirmPassword",
		placeholder: "confirmPassword",
		translate: false,
		required: true,
		type: "password",
		validator: [
			{
				required: true,
				message: `empty`,
				customValidator: "passSame"
			}
		],
		validateAppear: false
	}
];

export interface DrawerListItem {
	id: number;
	icon: () => JSX.Element;
	name: string;
	arrow: boolean;
	route: string;
	selected: boolean;
}

export const drawerListItems = [
	{
		id: 0,
		icon: ProfileIcon,
		name: "Login",
		arrow: true,
		route: "auth/login",
		selected: false
	},
	{
		id: 1,
		icon: BriefCase,
		name: "listyourservice",
		arrow: true,
		route: "partner",
		selected: false
	},
	{
		id: 2,
		icon: Refresh,
		name: "Packages",
		arrow: true,
		route: "packages?numberOfSeats=1",
		selected: false
	}
	// {
	//     id: 1,
	//     icon: Notifications,
	//     name: "Notifications",
	//     arrow: true,
	//     route: "notifications",
	//     selected: false
	// },
	// {
	//     id: 2,
	//     icon: ProfileIcon,
	//     name: "Login & Security",
	//     arrow: true,
	//     route: "login&security",
	//     selected: false
	// },
	// {
	//     id: 3,
	//     icon: Grid5,
	//     name: "Preferences",
	//     arrow: true,
	//     route: "preferences",
	//     selected: false
	// },
	// {
	//     id: 4,
	//     icon: Refresh,
	//     name: "Packages",
	//     arrow: true,
	//     route: "packages",
	//     selected: false
	// }
	// {
	//     id: 5,
	//     icon: ProfileIcon,
	//     name: "Paymentdetails",
	//     arrow: true,
	//     route: "payment_details",
	//     selected: false
	// }
	// {
	//     id: 6,
	//     icon: CrownIcon,
	//     name: "Reward points",
	//     arrow: true,
	//     route: "reward_points",
	//     selected: false
	// },
	// {
	//     id: 7,
	//     icon: CrownIcon,
	//     name: "Help support",
	//     arrow: true,
	//     route: "help_support",
	//     selected: false
	// },
	// {
	//     id: 8,
	//     icon: CrownIcon,
	//     name: "List Properties",
	//     arrow: true,
	//     route: "list_roperties",
	//     selected: false
	// }
];
export const drawerListItemsBusiness = [
	{
		id: 0,
		icon: ProfileIcon,
		name: "PersonalInfo",
		arrow: true,
		route: "personal-details",
		selected: false
	},
	{
		id: 1,
		icon: BriefCase,
		name: "Companyprofile",
		arrow: true,
		route: "company-profile",
		selected: false
	},
	{
		id: 2,
		icon: Notifications,
		name: "Notifications",
		arrow: true,
		route: "notifications",
		selected: false
	},
	{
		id: 3,
		icon: ProfileIcon,
		name: "LoginSecurity",
		arrow: true,
		route: "login&security",
		selected: false
	},
	{
		id: 4,
		icon: Grid5,
		name: "Preferences",
		arrow: true,
		route: "preferences",
		selected: false
	},
	{
		id: 5,
		icon: Refresh,
		name: "Packages",
		arrow: true,
		route: "packages",
		selected: false
	},
	{
		id: 14,
		icon: LinearTicket,
		name: "Mybookings",
		// name: t("payment.MyBookings"),
		arrow: true,
		route: "Bookings",
		selected: false,
		children: []
	},
	{
		id: 6,
		icon: ProfileIcon,
		name: "Paymentdetails",
		arrow: true,
		route: "payment_details",
		selected: false
	}
];

export const drawerListItemsEndCustomer = [
	{
		id: 0,
		icon: ProfileIcon,
		name: "PersonalDetails",
		arrow: true,
		route: "personal-details",
		selected: false
	},
	{
		id: 5,
		icon: Receiptminus,
		name: "Paymentdetails",
		arrow: true,
		route: "payment_details",
		selected: false
	},
	// {
	//     id: 1,
	//     icon: Notifications,
	//     name: "Notifications",
	//     arrow: true,
	//     route: "notifications",
	//     selected: false
	// },
	// {
	//     id: 2,
	//     icon: ProfileIcon,
	//     name: "Login & Security",
	//     arrow: true,
	//     route: "login&security",
	//     selected: false
	// },
	// {
	//     id: 3,
	//     icon: Grid5,
	//     name: "Preferences",
	//     arrow: true,
	//     route: "preferences",
	//     selected: false
	// },
	{
		id: 4,
		icon: LinearTicket,
		name: "Mybookings",
		// name: t("payment.MyBookings"),
		arrow: true,
		route: "Bookings",
		selected: false
	}

	// {
	//     id: 6,
	//     icon: CrownIcon,
	//     name: "Reward points",
	//     arrow: true,
	//     route: "reward_points",
	//     selected: false
	// },
	// {
	//     id: 7,
	//     icon: CrownIcon,
	//     name: "Help support",
	//     arrow: true,
	//     route: "help_support",
	//     selected: false
	// }
	// {
	//     id: 8,
	//     icon: CrownIcon,
	//     name: "List Properties",
	//     arrow: true,
	//     route: "list_roperties",
	//     selected: false
	// }
];

export const packageTypesSatusList: any = {
	waiting: {
		name: "Waiting for approve",
		color: "#FF9760"
	},
	published: {
		name: "Published",
		color: "#65C454"
	},
	postponded: {
		name: "Postponded",
		color: "#E65C5C"
	}
};

export const packageTypesStatus: any = {
	true: {
		name: "Published",
		color: "#65C454"
	},
	false: {
		name: "Unpublished",
		color: "#E65C5C"
	}
};
export type ProfileFormProps = {
	form: any;
	initialValues: any;
	fields: any;
	withPassword: boolean;
	translate: any;
	parameterType: string;
	disabled: boolean;
};
export type ProfileField = {
	label: string;
	type?: string;
	defaultValue?: string;
	items?: string[];
	required: boolean;
};
export const userProfileFields: ProfileField[] = [
	{ label: "First name", required: true },
	{ label: "Last name", required: true },
	{ label: "Email address", type: "email", required: true },
	{ label: "Phone number", type: "phone", defaultValue: "phoneNumber", required: true },
	{ label: "Nationality", type: "nationality", defaultValue: "nationality", required: true },
	{ label: "Gender", type: "select", defaultValue: "gender", required: true },
	{ label: "Date of birth", type: "date", required: false }
];

export const adminProfileFields: ProfileField[] = [
	{ label: "First name", required: true },
	{ label: "Last name", required: true },
	{ label: "Email address", type: "email", required: true },
	{ label: "Phone number", type: "phone", defaultValue: "phone_number", required: true }
];
export const businessProfileFields: ProfileField[] = [
	{ label: "Company name", required: true },
	{ label: "Company number", type: "phone", defaultValue: "phoneNumber", required: true },
	// { label: "Email address", type: "email", required: true },
	{ label: "Nationality", type: "nationality", defaultValue: "nationality", required: true },
	{ label: "Commercial Record", type: "file", required: false }
];
export const businessItems = [
	{
		title: "Umrah operator",
		itemId: 1,
		img: "/assets/common/images/business_type1.png",
		contentIds: [1, 2, 3, 4, 5, 6, 7, 8]
	},
	{
		title: "Business buyer (Retailer)",
		itemId: 2,
		img: "/assets/common/images/business_type2.png",
		contentIds: [1]
	},
	{
		title: "Service Provider",
		itemId: 3,
		img: "/assets/common/images/business_type3.png",
		contentIds: [1, 2, 3]
	}
];
export const getStartedItems = [
	{ Icon: GettingStarted1, id: 1 },
	{ Icon: GettingStarted2, id: 2 },
	{ Icon: GettingStarted3, id: 3 }
];
export const faqItems = [
	{ title: "Who can join ?", text: "item 1 content" },
	{
		title: "What's required to apply ?",
		text: "To work with us, you'll need to be a professional supplier and fill out the application with your company info and social media or website, so we can learn more about your business. Once you’re confirmed, we’ll need your business registration, liability insurance, and banking details for payout."
	},
	{ title: "How and when do I get paid ?", text: "item 3 content" },
	{ title: "Can I host more than 1 service ?", text: "item 4 content" }
];

export const tabsArray = (user: any): any[] => {
	return [
		{ id: 0, name: "stays", link: "/", icon: AwesomeHome, soon: true },
		{ id: 1, name: "flights", link: "/", icon: MaterialFlightTakeoff, soon: true },
		{ id: 2, name: "transfers", link: "/", icon: NounBus2558229, soon: true },
		{ id: 3, name: "thingsToDo", link: "/", icon: Group_15095, soon: true },
		{ id: 4, name: "packages", link: +user?.userType === 3 ? "/profile/packages" : "/p?numberOfSeats=1", icon: Group_15018, soon: false },
		{ id: 5, name: "cars", link: "/", icon: AwesomeCar, soon: true },
		{ id: 6, name: "umrahJourney", link: "/", icon: AwesomePrayingHands, soon: false }
	];
};
export const servicesArray = [
	{ id: 0, name: "aboutDyafat", link: "/" },
	{ id: 1, name: "dyafatPolicies", link: "/topics/user_agreement" },
	{ id: 2, name: "news", link: "/" }
	// { id: 3, name: "blog", link: "/" }
];
function t(arg0: string) {
	throw new Error("Function not implemented.");
}
